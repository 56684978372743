import { Avatar, Box, Button, Input, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { TiCameraOutline } from "react-icons/ti";
import TextInput from "../../../components/TextInput";
import { createAiImageService } from "../../../services/aiImage/aiImage";
import { logo } from "../../../assets";
import { BsFillCameraFill } from "react-icons/bs";

const validationSchema = yup.object({
  mobile: yup.string().max(14).required("mobile is required"),
  image: yup
    .mixed()
    // .nullable()
    .test("fileSize", "File too large", (value) => {
      if (value) return value && value?.size && value.size <= 2 * 1024 * 1024;
      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value) return value && value?.type?.includes("image");
      return true;
    })
    .required("image is required"),
});

const AiForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentImage, setCurrentImage] = useState({
    media: "",
  });

  const formik = useFormik({
    initialValues: { mobile: "", image: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { mobile, image } = values;
        let sendData = new FormData();
        mobile && sendData.append("phone", mobile);
        image && sendData.append("image", image);
        const { error } = await createAiImageService({ sendData });
        if (error) return setError(error);
        setCurrentImage({ media: "" });
        formik.resetForm();
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const uploadPhoto = (e) => {
    const imageSelected = e.target.files[0];
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setCurrentImage({ media: reader.result });
        formik.setFieldValue("image", imageSelected);
      },
      false
    );
    reader.readAsDataURL(imageSelected);
  };

  return (
    <>
      <Box width={1} sx={{ px: 3, py: 7 }}>
        <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="" />
        </Box>
        <Typography variant="h6" fontWeight={600} sx={{ my: 5 }}>
          Enter Your Data 😍
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label htmlFor="contained-button-file">
              <Input
                id="contained-button-file"
                inputProps={{
                  multiple: true,
                  accept: "image/jpeg, image/png, image/gif",
                }}
                type="file"
                sx={{ display: "none" }}
                onChange={uploadPhoto}
                error={formik.touched.image && Boolean(formik.errors.image)}
              />
              <Avatar
                alt={""}
                src={currentImage?.media}
                sx={{
                  height: 132,
                  width: 132,
                  fontSize: "3rem",
                  backgroundColor: "#EBEBEB",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "background.secondary",
                    width: "80px",
                    height: "80px",
                    display: "grid",
                    placeItems: "center",
                    p: 0,
                    borderRadius: "50%",
                    color: "text.secondary",
                  }}
                  size="small"
                >
                  <BsFillCameraFill size="58px" color="#3E3E3E" />
                </Box>
              </Avatar>
            </label>
            {formik.errors.image && (
              <Box width={1} textAlign="center">
                <Typography
                  variant="caption"
                  textALign="center"
                  color="error"
                  width={1}
                >
                  {formik.errors.image}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ mt: 4 }}>
            <TextInput
              label={"Mobile Number"}
              placeholder={"Enter your mobile number ex, 011 xxx xxx xx"}
              name="mobile"
              formik={formik}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                borderRadius: "6px",
                border: "none",
                backgroundColor: "#EBEBEB",
                p: 2,
                width: 1,
              }}
            />
          </Box>
          {error && (
            <Box sx={{ my: 3 }}>
              <Typography
                variant="caption"
                textALign="center"
                color="secondary"
                width={1}
              >
                {error}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", pt: 7 }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                py: 2,
                maxWidth: "395px",
                width: 1,
                borderRadius: "50px",
                boxShadow: "0px 4px 20px 0px #0E5B7361",
                mt: 8,
              }}
              disabled={loading}
            >
              Send
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AiForm;
