import { Box, Grid, Typography } from "@mui/material";
import AiForm from "./components/AiForm";
import { bg, formBg, illustrationLarge, illustrationSmall } from "../../assets";
import ImageWithLoader from "../../components/ImageWithLoader";

const AiImagePage = () => {
  return (
    <Grid container columnSpacing={2} sx={{ minHeight: "100dvh" }}>
      <Grid item xs={12} md={8} lg={9}>
        <Box
          sx={{
            height: 1,
            width: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            minHeight: "296px",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: 1,
              overflow: "hidden",
              zIndex: -1,
            }}
          >
            <ImageWithLoader
              src={bg}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: 1,
              display: { xs: "none", md: "inline-block" },
              overflow: "hidden",
            }}
          >
            {/* <img
              src={illustrationLarge}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt=""
            /> */}
            <ImageWithLoader
              src={illustrationLarge}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
          <Typography
            sx={{ maxWidth: { xs: 0.5, md: "521px" }, ml: { xs: 2, md: 7 } }}
            fontWeight={600}
            variant="h6"
            color="primary.contrastText"
          >
            Enjoy the event and be surprised by the spontaneous shots we
            capture! 😍
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Box
          sx={{
            mt: { xs: -5, md: 0 },
            height: 1,
            width: 1,
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "80%",
              right: 0,
              width: 1,
              height: "496px",
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
              zIndex: 3,
            }}
          >
            <ImageWithLoader
              src={illustrationSmall}
              style={{
                width: "80%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "right",
              }}
            />
          </Box>
          <Box
            sx={{
              height: 1,
              width: 1,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                width: 1,
                overflow: "hidden",
                zIndex: -1,
                borderRadius: { xs: "24px 24px 0px 0px", md: "unset" },
              }}
            >
              <ImageWithLoader
                src={formBg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <AiForm />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AiImagePage;
