import { createTheme, ThemeProvider } from "@mui/material";
import AiImagePage from "./pages/AiImagePage/AiImagePage";
import { useMemo } from "react";
import getDesignTokens from "./theme";

function App() {
  const theme = useMemo(() => {
    return createTheme({ ...getDesignTokens() });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AiImagePage />
    </ThemeProvider>
  );
}

export default App;
