const getDesignTokens = () => ({
  palette: {
    primary: {
      main: "#187E9E",
      background: "#187E9E",
      default: "#187E9E",
      white: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF9999",
      background: "#187E9E",
      default: "#FF9999",
      contrastText: "#fff",
    },
    divider: "#000000",
    text: {
      primary: "#000000",
    },
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #202225",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#fff",
            width: 8,
            height: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#4849A1",
            minHeight: 24,
            border: "3px solid #4849A1",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#4849A1",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#4849A1",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#4849A1",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
});

export default getDesignTokens;
