import { postRequest } from "../../connection/network";

const aiApi = {
  create: "ai-image",
};

export const createAiImageService = async ({ sendData }) => {
  try {
    let {
      data: { data, message },
    } = await postRequest({
      body: sendData,
      endPoint: aiApi.create,
      isFormData: true,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
