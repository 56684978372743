import { Typography, FormHelperText, TextField } from "@mui/material";
const TextInput = ({
  name,
  label,
  placeholder,
  formik,
  values,
  onChange,
  sx,
  ...props
}) => {
  const isError =
    formik.touched[name] &&
    formik?.touched[name] &&
    Boolean(formik?.errors[name]);

  return (
    <>
      <Typography sx={{ mb: 1 }} fontWeight={500}>
        {label}
      </Typography>
      <TextField
        {...props}
        fullWidth
        id={name}
        name={name}
        value={formik?.values[name] || values || ""}
        onChange={onChange || formik?.handleChange}
        error={(formik?.touched[name] && Boolean(formik?.errors[name])) || ""}
        helperText={formik.touched[name] && formik.errors[name]}
        placeholder={placeholder}
        sx={{
          fieldset: { legend: { width: "unset" } },
          borderRadius: "12px",
          border: "1px solid #E5E7EB",
          backgroundColor: "#fff",
          ...sx,
        }}
      />
      {isError && (
        <FormHelperText error id="accountId-error" sx={{ mb: 2 }}>
          {formik.errors[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default TextInput;
